const breakpoints = [375, 576, 768, 992, 1400];

const theme = {
  grid: {
    breakpoints: {
      xl: breakpoints[4],
      lg: breakpoints[3],
      md: breakpoints[2],
      sm: breakpoints[1],
      xs: breakpoints[0],
      xxs: 0,
    },
    col: {
      padding: 20,
    },
    row: {
      padding: 20,
    },
    container: {
      padding: 20,
      maxWidth: {
        xl: 1320,
        lg: 960,
        md: 750,
        sm: 540,
        xs: 400,
      },
    },
  },
  colors: {
    primary: '#E9E9E9',
    secondary: '#5A5A5A',
    lightGray: '#C6C6C6',
    darkGray: '#454545',
    white: '#FFF',
  },
  fontFamily:
    'Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
  zIndex: {
    nav: 900,
    sidebar: 1000,
  },
  space: [
    0,
    '0.4rem',
    '0.8rem',
    '1.2rem',
    '1.6rem',
    '2.0rem',
    '2.4rem',
    '2.8rem',
    '3.2rem',
    '3.6rem',
    '4.0rem',
    '4.4rem',
    '4.8rem',
    '5.2rem',
    '5.6rem',
    '6.0rem',
    '6.4rem',
    '6.8rem',
    '7.2rem',
    '7.6rem',
    '8.0rem',
  ],
};

theme.breakpoints = breakpoints.map((p) => `${p}px`);
theme.breakpoints.xs = `${breakpoints[0]}px`;
theme.breakpoints.sm = `${breakpoints[1]}px`;
theme.breakpoints.md = `${breakpoints[2]}px`;
theme.breakpoints.lg = `${breakpoints[3]}px`;
theme.breakpoints.xl = `${breakpoints[4]}px`;

export default theme;
