export const AUDIO_STATES = {
  MUTED: 'muted',
  UNMUTED: 'unmuted',
  LOADING: 'loading',
};

export const PLAYER_STATES = {
  PLAYING: 'playing',
  PAUSED: 'paused',
  ENDED: 'ended',
};
