import styled from 'styled-components';

export const Circle = styled.span`
  width: 9px;
  height: 9px;
  display: block;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.color};
  background-color: ${(props) => (props.filled ? props.color : 'transparent')};
`;

export const Button = styled.div`
  color: ${(props) => props.theme.colors.lightGray};
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 3px;
  padding: 0 10px 2px 10px;
  height: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
  &:hover {
    background-color: ${(props) => props.theme.colors.darkGray};
  }
`;
