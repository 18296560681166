import React, { useEffect, useState, useContext } from 'react';
import { ResonanceAudio } from 'resonance-audio';
import AudioContext from 'ios-safe-audio-context';
import { PLAYER_STATES } from './constants';
import AudioElement from './audioElement';

class Player {
  constructor() {
    this.audioElements = [];
    this.audioContext = new AudioContext();
    this.audioContext.close();
    this.audioContext = new AudioContext();
    this.downloadProgress = { loaded: 0, total: 0, complete: false };
    this.onStateChangeCallback = () => {};
    this.onAudioElementStateChange = () => {};
    this.onDownloadProgressChange = () => {};
    this.onEnded = () => {};

    this.state = PLAYER_STATES.PAUSED;
  }

  loadSources(sources) {
    this.scene = new ResonanceAudio(this.audioContext, {
      ambisonicOrder: 1,
    });
    this.scene.setListenerPosition(0, 0, 0);
    const roomDimensions = { width: 8, height: 3.2, depth: 8 };
    this.scene.setRoomProperties(roomDimensions, {
      left: 'brick-bare',
      right: 'brick-bare',
      up: 'brick-bare',
      down: 'wood-panel',
      front: 'brick-bare',
      back: 'brick-bare',
    });
    this.soundSources = [];

    sources.forEach((audioInfo, i) => {
      const a = new AudioElement(
        audioInfo,
        this.audioContext,
        this.scene,
        roomDimensions
      );
      a.onStateChange = (state) => this.onAudioElementStateChange(i, state);
      a.onEnded = () => {
        console.log('PLAYER ENDED');
        this.audioElements.forEach((e) => e.rewind());
        this.audioContext.suspend();
        this.state = PLAYER_STATES.ENDED;
        this.onStateChangeCallback(this.state);
      };
      a.onDownloadProgressChange = () => {
        this.calcDownloadProgress();
      };
      this.audioElements.push(a);
    });

    this.scene.output.connect(this.audioContext.destination);
    return Promise.all(this.audioElements.map((a) => a.load()));
  }

  calcDownloadProgress() {
    this.downloadProgress.total = 0;
    this.downloadProgress.loaded = 0;
    let completed = true;
    this.audioElements.forEach((e) => {
      this.downloadProgress.total += e.downloadProgress.total;
      this.downloadProgress.loaded += e.downloadProgress.loaded;
      if (e.downloadProgress.state === 'DOWNLOADING') {
        completed = false;
      }
    });
    this.downloadProgress.complete = completed;
    this.onDownloadProgressChange(this.downloadProgress);
  }

  play() {
    this.audioElements.forEach((e) => e.play());
    this.audioContext.resume();
    this.state = PLAYER_STATES.PLAYING;
    this.onStateChangeCallback(this.state);
  }

  pause() {
    this.audioContext.suspend();
    this.state = PLAYER_STATES.PAUSED;
    this.onStateChangeCallback(this.state);
  }

  rewind() {
    this.audioElements.forEach((e) => e.rewind());
  }

  getAnalyserData(index) {
    return this.audioElements[index].getAnaylserData();
  }

  getAudioElement(index) {
    return this.audioElements[index];
  }

  getAudioElements() {
    return this.audioElements;
  }

  onStateChange(fn) {
    this.onStateChangeCallback = fn;
  }

  setRoomProperties(properties) {
    if (!this.scene) {
      return;
    }
    this.audioElements.forEach((e) =>
      e.setRoom(properties.width, properties.depth)
    );
    this.scene.setRoomProperties(
      {
        width: properties.width,
        height: properties.height,
        depth: properties.depth,
      },
      {
        left: properties.left || 'brick-bare',
        right: properties.right || 'brick-bare',
        up: properties.up || 'brick-bare',
        down: properties.down || 'wood-panel',
        front: properties.front || 'brick-bare',
        back: properties.back || 'brick-bare',
        // left: 'brick-bare',
        // right: 'brick-bare',
        // up: 'brick-bare',
        // down: 'wood-panel',
        // front: 'brick-bare',
        // back: 'brick-bare',
      }
    );
  }
}

const PlayerContext = React.createContext(new Player());
export default PlayerContext;

const PlayerProvider = ({ children }) => {
  const player = useContext(PlayerContext);
  const [state, setState] = useState(player.state);
  const [downloadProgress, setDownloadProgress] = useState(
    player.downloadProgress
  );
  const [audioElementsState, setAudioElementsState] = useState(
    player.audioElements.map((a) => a.state)
  );

  const [audioElements, setAudioElements] = useState(player.audioElements);

  useEffect(() => {
    player.onStateChange(setState);
    player.onAudioElementStateChange = (index, s) => {
      // console.log(index, state);
      const states = [...audioElementsState];
      states[index] = s;
      // console.log(states);
      setAudioElementsState(states);
      setAudioElements([...player.audioElements]);
    };
    player.onDownloadProgressChange = (p) => {
      // console.log(p)
      setDownloadProgress({ ...p });
    };
  }, [audioElementsState, player]);

  const setRoomProperties = (properties) => {
    player.setRoomProperties(properties);
  };

  return (
    <PlayerContext.Provider
      value={{
        player,
        state,
        audioElementsState,
        downloadProgress,
        audioElements,
        setRoomProperties,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};
export { PlayerProvider };
