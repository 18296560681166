import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Circle } from '../styles';

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  padding: 5px 10px 8px 24px;
  white-space: nowrap;
  height: 35px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};
  &:hover {
    background-color: ${(props) => props.theme.colors.darkGray};
  }
`;

const StyledCircle = styled(Circle)`
  position: absolute;
  left: 8px;
  bottom: ${(props) => (props.active ? '27px' : '9px')};
`;

const AudioSourceCard = ({
  artistName,
  instrumentName,
  ensembleName,
  color,
  active,
}) => (
  <Container>
    <div>
      <StyledCircle color={color} filled={active} active={active} />
      <div>
        {artistName} - {instrumentName}
      </div>
      <div>{ensembleName}</div>
    </div>
  </Container>
);

AudioSourceCard.propTypes = {
  artistName: PropTypes.string.isRequired,
  instrumentName: PropTypes.string.isRequired,
  ensembleName: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default AudioSourceCard;
