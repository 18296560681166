import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Mousewheel } from 'swiper';
import { AUDIO_STATES } from '../../utils/constants';

import 'swiper/swiper.scss';
import AudioSourceCard from '../audioSourceCard';

const StyledHint = styled.div`
  position: absolute;
  right: 10px;
  top: -29px;
`;

const Arrow = () => (
  <svg width="9px" height="9px" viewBox="0 0 9 9">
    <title>→</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="4.2-Desktop-Horizontal-Scroll-Indicator"
        transform="translate(-1421.000000, -741.000000)"
      >
        <g id="background" transform="translate(-98.000000, -226.000000)" />
        <g
          id="Scroll-indicator"
          transform="translate(1327.000000, 734.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <polygon
            id="→"
            points="98.56 15.12 102.16 11.124 98.56 7.128 96.94 7.128 100.06 10.536 94 10.536 94 11.724 100.048 11.724 96.94 15.12"
          />
        </g>
      </g>
    </g>
  </svg>
);

SwiperCore.use([Mousewheel]);
const AudioSourceControls = ({ className, audioElements, onActivate }) => {
  const [firstUse, setFirstUse] = useState(true);
  const [swiper, setSwiper] = useState(null);
  const [showHint, setShowHint] = useState(true);
  useEffect(() => {
    if (swiper) {
      const onMove = () => {
        setShowHint(false);
      };
      swiper.on('transitionStart', onMove);
      return () => {
        swiper.off('transitionStart', onMove);
      };
    }
  }, [swiper]);
  return (
    <div className={className}>
      {showHint && (
        <StyledHint>
          Scroll for more <Arrow />
        </StyledHint>
      )}
      {audioElements.length > 0 && (
        <Swiper
          spaceBetween={5}
          slidesPerView="auto"
          slidesOffsetBefore={10}
          slidesOffsetAfter={10}
          threshold={10}
          mousewheel
          onSwiper={setSwiper}
        >
          {audioElements.map((e, i) => (
            <SwiperSlide key={i} style={{ width: '250px' }}>
              <div
                role="button"
                aria-hidden="true"
                onClick={() => {
                  if (firstUse) {
                    onActivate();
                    setFirstUse(false);
                  }
                  if (e.state === AUDIO_STATES.MUTED) {
                    e.unMute();
                  } else {
                    e.mute();
                  }
                }}
              >
                <AudioSourceCard
                  artistName={e.info.artistName}
                  ensembleName={e.info.ensembleName}
                  instrumentName={e.info.name}
                  color={e.info.colorHex}
                  active={e.state === AUDIO_STATES.UNMUTED}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

AudioSourceControls.propTypes = {
  className: PropTypes.string,
  audioElements: PropTypes.array,
  onActivate: PropTypes.func.isRequired,
};

export default AudioSourceControls;
