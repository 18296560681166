import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '../styles';
import media from '../../utils/media';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Headline = styled.div`
  text-align: center;
  max-width: 280px;
  font-size: 48px;
  line-height: 48px;
  ${media.up.md`
    max-width: 560px;
    font-size: 96px;
    line-height: 96px;
  `}
`;

const Text = styled.div`
  max-width: 280px;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const LoadingText = styled.div`
  font-size: 12px;
`;

const ProgressBar = styled.div`
  position: relative;
  margin-top: 10px;
  width: 280px;
  height: 3px;
  border: 1px solid ${(props) => props.theme.colors.primary};
`;

const ProgressBarInner = styled.div.attrs((props) => ({
  style: {
    width: `${props.progress}%`,
  },
}))`
  content: '';
  position: absolute;
  background-color: ${(props) => props.theme.colors.primary};
  height: 100%;
  transition: 0.5s all;
`;

const LoadingScreen = ({
  className,
  loading,
  percent,
  onClose,
  projectName,
  introText,
}) => (
  <Container className={className}>
    <Headline>{projectName}</Headline>
    <Text>{introText}</Text>
    {loading ? (
      <div>
        <LoadingText>Inhalte werden geladen...</LoadingText>
        <ProgressBar>
          <ProgressBarInner progress={percent} />
        </ProgressBar>
      </div>
    ) : (
      <Button onClick={onClose}>Enter</Button>
    )}
  </Container>
);

LoadingScreen.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  percent: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default LoadingScreen;
