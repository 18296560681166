import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  color: black;
`;

const Window = styled.div`
  position: absolute;
  top: 10px;
  left: 50%;
  margin-bottom: 10px;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  max-width: 720px;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 7px 10px 10px 10px;
  border-radius: 3px;
  box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.5);
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const Popup = ({ children, visible, onClose }) => {
  if (!visible) return false;
  return (
    <Container>
      <Background onClick={onClose} />
      <Window>
        <Close onClick={onClose}>
          <img src="/icons/close.svg" alt="close" />
        </Close>
        {children}
      </Window>
    </Container>
  );
};

Popup.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default Popup;
