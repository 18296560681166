import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'react-spring-accordion';
import styled, { useTheme } from 'styled-components';
import { Circle } from '../styles';
import { AUDIO_STATES } from '../../utils/constants';

const Container = styled.div`
  color: black;
`;

const Header = styled.div`
  position: relative;
  text-align: left;
  border-top: ${(props) => (props.first ? '1px solid black' : 'none')};
  padding: 5px 0 7px 24px;
  cursor: pointer;
`;

const Line = styled.div`
  border-bottom: 1px solid black;
`;

const Content = styled.div`
  p {
    margin: 0;
    padding: 14px 0 0 0;
    &:last-child {
      padding-bottom: 14px;
    }
  }
`;

const StyledCircle = styled(Circle)`
  position: absolute;
  left: 8px;
  top: 7px;
`;

const PresetList = styled.div`
  list-style: none;
  margin-bottom: 28px;
  padding: 0;
  p {
    margin: 0;
  }
`;
const PresetItem = styled.button`
  position: relative;
  appearance: none;
  background-color: transparent;
  border: 0px;
  text-align: left;
  outline: none;
  width: 100%;
  border: 0;
  border-bottom: 1px solid black;
  padding: 5px 0 7px 24px;
  font-size: 14px;
  cursor: pointer;
  &:first-child {
    border-top: 1px solid black;
  }
`;

const PresetDescription = styled.div`
  margin-top: 7px;
  margin-bottom: 7px;
`;

const Imprint = styled.div`
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid black;
  text-decoration: underline;
  a {
    color: black;
  }
`;

const About = ({
  audioElements,
  projectName,
  text1,
  presetsText,
  text2,
  imprintURL,
  presets,
  currentPreset,
  onSelectPreset,
}) => {
  const theme = useTheme();
  return (
    <Container>
      <div>{projectName}</div>
      <div dangerouslySetInnerHTML={{ __html: text1 }} />
      <Accordion.Wrapper>
        {audioElements.map((a, i) => (
          <Accordion.Item key={a.info.id}>
            <Accordion.Heading
              style={{ outline: 'none', width: '100%', padding: 0 }}
            >
              <Header first={i === 0} style={{ fontSize: '14px' }}>
                <StyledCircle
                  color={a.info.colorHex}
                  filled={a.state === AUDIO_STATES.UNMUTED}
                />
                <div>
                  {a.info.artistName} - {a.info.name}
                </div>
                <div>
                  {a.info.ensembleName}, {a.info.city}
                </div>
              </Header>
            </Accordion.Heading>
            <Accordion.Child>
              <Content dangerouslySetInnerHTML={{ __html: a.info.content }} />
            </Accordion.Child>
            <Line />
          </Accordion.Item>
        ))}
      </Accordion.Wrapper>
      <div dangerouslySetInnerHTML={{ __html: presetsText }} />
      {presets.length > 0 && (
        <>
          <PresetList>
            {presets.map((p, i) => (
              <PresetItem key={p.id} onClick={() => onSelectPreset(i)}>
                <StyledCircle
                  color={theme.colors.secondary}
                  filled={currentPreset === p.id}
                />
                <div>{p.title}</div>
                {p.content && (
                  <div dangerouslySetInnerHTML={{ __html: p.content }} />
                )}
              </PresetItem>
            ))}
          </PresetList>
        </>
      )}
      <div dangerouslySetInnerHTML={{ __html: text2 }} />
      <Imprint>
        <a href={imprintURL} target="_blank" rel="noopener noreferrer">
          Show Imprint
        </a>
      </Imprint>
    </Container>
  );
};

About.propTypes = {
  audioElements: PropTypes.array.isRequired,
  projectName: PropTypes.string.isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  imprintURL: PropTypes.string.isRequired,
  presets: PropTypes.array,
  currentPreset: PropTypes.string,
  onSelectPreset: PropTypes.func,
};

export default About;
