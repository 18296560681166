import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { useTrail, a } from 'react-spring';

import { Button, Circle } from '../styles';

const Container = styled.div`
  display: flex;
`;

const MenuButtonWrapper = styled.div`
  margin-right: 5px;
`;

const SlideOutContainer = styled(a.div)`
  display: flex;
`;

function Trail({ open, children, ...props }) {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 2, tension: 2000, friction: 100 },
    opacity: open ? 1 : 0,
    x: open ? 0 : -5,
    // height: open ? 110 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });
  return (
    <div className="trails-main" {...props}>
      <SlideOutContainer>
        {trail.map(({ x, height, ...rest }, index) => (
          <a.div
            key={index}
            className="trails-text"
            style={{
              ...rest,
              transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
            }}
          >
            <a.div style={{ height }}>{items[index]}</a.div>
          </a.div>
        ))}
      </SlideOutContainer>
    </div>
  );
}

const Nav = (props) => {
  const theme = useTheme();
  const [open, set] = useState(true);
  return (
    <Container className={props.className}>
      <MenuButtonWrapper>
        <Button onClick={() => set((state) => !state)}>
          <Circle color={theme.colors.white} filled={open} />
        </Button>
      </MenuButtonWrapper>
      <Trail open={open}>
        {React.Children.map(props.children, (child) => (
          <MenuButtonWrapper>{child}</MenuButtonWrapper>
        ))}
      </Trail>
    </Container>
  );
};

Nav.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Nav;
