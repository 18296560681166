import axios from 'axios';
import { AUDIO_STATES } from './constants';

export default class AudioElement {
  constructor(audioInfo, audioContext, scene, roomSettings) {
    this.audio = document.createElement('audio');
    this.downloadProgress = { loaded: 0, total: 0, state: 'DOWNLOADING' };
    this.onStateChange = () => {};
    this.onDownloadProgressChange = () => {};
    this.onEnded = () => {};
    this.first = false;

    // this.audio.src = audioInfo.url;
    // this.audio.crossOrigin = 'anonymous';
    // this.audio.load();

    this.audioElementSource = audioContext.createMediaElementSource(this.audio);
    this.soundSource = scene.createSource();
    this.soundSource.setPosition(Math.random() * 2, 0, Math.random() * 2);
    this.audioElementSource.connect(this.soundSource.input);
    // this.audioElementSource.connect(audioContext.destination)
    console.log('channelCount', this.audioElementSource.channelCount);
    this.analyser = audioContext.createAnalyser();
    this.analyser.fftSize = 256;
    this.audioElementSource.connect(this.analyser);
    this.data = new Uint8Array(this.analyser.frequencyBinCount);

    // this.mute()
    this.state = AUDIO_STATES.MUTED;
    this.name = audioInfo.name;
    this.color = audioInfo.color;
    this.url = audioInfo.url;
    this.info = audioInfo;
    this.soundSource.setGain(0);
    this.room = {
      width: roomSettings.width,
      depth: roomSettings.depth,
    };
  }

  load() {
    return new Promise((resolve, reject) => {
      const config = {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          if (!this.first) {
            console.log('started', this.url);
            this.first = true;
          }
          this.downloadProgress.loaded = progressEvent.loaded;
          this.downloadProgress.total = progressEvent.total;
          this.onDownloadProgressChange(this.downloadProgress);
        },
      };
      axios
        .get(this.url, config)
        .then((response) => {
          // new File([response.data], fileName);
          this.audio.src = window.URL.createObjectURL(response.data);
          this.downloadProgress.state = 'FINISHED';
          this.onDownloadProgressChange(this.downloadProgress);
          console.log('finished', this.url);
          resolve();
          // console.log(this.audio)
          // this.audio.addEventListener('canplaythrough', () => {
          //   console.log(`loaded this.audio file`, audioInfo.url)
          // })
          // this.audio.addEventListener('waiting', () => {
          //   console.log(`waiting`, audioInfo.url)
          // })
          // this.audio.addEventListener('error', e => {
          //   console.log(`error`, audioInfo.url, e)
          // })
          // this.audio.addEventListener('stalled', e => {
          //   console.log(`stalled`, audioInfo.url, e)
          // })
          // this.audio.addEventListener('progress', e => {
          //   console.log(`progress`, audioInfo.url, e)
          // })
          // this.audio.addEventListener('waiting', e => {
          //   console.log(`waiting`, audioInfo.url, e)
          // })
          this.audio.addEventListener('ended', (e) => {
            console.log(`ended`, this.url, e);
            this.onEnded();
          });
        })
        .catch((e) => {
          console.log(e, this.url);
          reject();
        });
    });
  }

  play() {
    this.audio.play();
  }

  pause() {
    this.audio.pause();
  }

  rewind() {
    this.audio.currentTime = 0;
  }

  mute() {
    this.state = AUDIO_STATES.MUTED;
    this.soundSource.setGain(0);
    this.onStateChange(this.state);
  }

  unMute() {
    this.state = AUDIO_STATES.UNMUTED;
    this.soundSource.setGain(1);
    this.onStateChange(this.state);
  }

  getAnaylserData() {
    this.analyser.getByteFrequencyData(this.data);
    return [...this.data];
  }

  setPosition(x, y) {
    this.posX = x;
    this.posY = y;
    this.soundSource.setPosition(
      this.posX * this.room.width,
      0,
      this.posY * this.room.depth
    );
  }

  setRoom(w, d) {
    this.room.width = w;
    this.room.depth = d;
    this.setPosition(this.posX, this.posY);
  }
}
