import React from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated } from 'react-spring';

const CommentLine = ({ texts }) => {
  const transitions = useTransition(texts, (item) => item, {
    from: { transform: 'translate3d(0,-20px,0)', opacity: 0 },
    enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
    leave: { transform: 'translate3d(0, 20px,0)', opacity: 0 },
  });
  return transitions.map(({ item, props, key }) => (
    <animated.div key={key} style={{ position: 'absolute', ...props }}>
      {item}
    </animated.div>
  ));
};

CommentLine.propTypes = {};

export default CommentLine;
