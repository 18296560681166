import React, { useRef, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTweaks, makeMonitor } from 'use-tweaks';

const particle = {
  vx: 0,
  vy: 0,
  ax: 0,
  ay: 0,
  x: 0,
  y: 0,
  color: [255, 255, 255],
  inCircle: false,
};

const GRID_COLOR = [255, 255, 255];

const SPACING = 10;
// const THICKNESS = Math.pow( 40, 2 )

const FRAME_DURATION = 1000 / 30;
const MAX_ROOM_RADIUS = 700;
const SOUND_SOURCE_FORCE = 2;
const ORIGIN_FORCE = 0.03;
const FRICTION = 0.75;
const MAX_PARTICLES_OUTSIDE_MULTIPLIER = 1;
const USE_FULL_SCREEN_BREAKPOINT = 768;
const ROOM_OFFSET_Y = 35;
const ROOM_PADDING = 30;
let imageData = null;
let currentFrame = 0;

const Room = (props) => {
  const canvasRef = useRef(null);
  const tweakRef = useRef(null);
  const fps = useRef(0);
  const [showTweakPane, setShowTweakPane] = useState(false);
  useTweaks(
    'FPS',
    {
      ...makeMonitor('graph', fps, {
        view: 'graph',
        min: 0,
        max: +60,
      }),
      ...makeMonitor('num', fps, {
        multiline: true,
        count: 10,
        interval: 250,
      }),
    },
    { container: tweakRef }
  );
  const {
    spacing,
    gridColor,
    maxRadius,
    numberOfParticlesOutsideRoomMultiplier,
  } = useTweaks(
    'Grid',
    {
      spacing: { value: SPACING, min: 2, max: 20, step: 1 },
      gridColor: { r: 255, g: 255, b: 255 },
      maxRadius: { value: MAX_ROOM_RADIUS, min: 100, max: 1000, step: 10 },
      numberOfParticlesOutsideRoomMultiplier: {
        label: 'max particles outside multiplier',
        value: MAX_PARTICLES_OUTSIDE_MULTIPLIER,
        min: 0,
        max: 5,
        step: 0.1,
      },
    },
    { container: tweakRef }
  );

  const { soundSourceForce, forceToOrigin, friction } = useTweaks(
    {
      soundSourceForce: {
        label: 'sound source force',
        value: SOUND_SOURCE_FORCE,
        min: 1,
        max: 20,
      },
      forceToOrigin: {
        label: 'force to origin',
        value: ORIGIN_FORCE,
        min: 0.01,
        max: 0.3,
        step: 0.01,
      },
      friction: { value: FRICTION, min: 0.4, max: 0.99, step: 0.01 },
    },
    { container: tweakRef }
  );

  const { thickness } = useTweaks('Sound Sources', {
    thickness: { value: 40, min: 10, max: 200, step: 10 },
  });

  const roomRadius = useRef(maxRadius);
  const sourceSize = useRef(
    Math.min(thickness * (roomRadius.current / MAX_ROOM_RADIUS), thickness)
  );

  const thicknessPow = useRef(sourceSize.current ** 2);

  const list = useRef([]);
  const sourcePositions = useRef([]);
  const globalSourcePositions = useRef([]);
  const draggingSourceId = useRef(-1);
  const hoveringSourceId = useRef(-1);

  const {
    getColorForSource,
    isSourceActive,
    getAnalyserData,
    onSourceMouseOver,
    onSourceMouseOut,
    className,
  } = props;

  const lastDraw = useRef(0);
  const canvasSize = useRef({ width: 0, height: 0 });
  const loadingState = useRef(null);
  const sourcePositionChangeHandler = useRef(null);
  const sourceMouseOverHandler = useRef(null);
  const sourceMouseOutHandler = useRef(null);
  const roomCenter = useRef({ x: 0, y: 0 });
  const [useRoom, setUseRoom] = useState(true);
  const audioSourceCircleAlpha = useRef(0);
  const audioSourceCircleTargetAlpha = useRef(0);

  const drawAudioSources = useCallback(
    (ctx, deltaTime) => {
      globalSourcePositions.current.forEach((pos, i) => {
        if (isSourceActive(i)) {
          const c = getColorForSource(i);
          ctx.strokeStyle = `rgb(${c[0]},${c[1]},${c[2]})`;
          ctx.save();
          audioSourceCircleAlpha.current -=
            (audioSourceCircleAlpha.current -
              audioSourceCircleTargetAlpha.current) *
            0.02 *
            deltaTime;
          ctx.globalAlpha = 1;
          if (hoveringSourceId.current !== i) {
            ctx.globalAlpha = audioSourceCircleAlpha.current;
          }
          ctx.beginPath();
          ctx.arc(pos.x, pos.y, sourceSize.current * 0.6, 0, 2 * Math.PI);
          ctx.stroke();
          ctx.restore();
        }
      });
    },
    [isSourceActive]
  );

  const drawParticles = useCallback(
    (ctx, analyserDataSets, deltaTime) => {
      if (canvasSize.current.width === 0 || canvasSize.current.height === 0) {
        return;
      }

      const image = ctx.createImageData(
        canvasSize.current.width,
        canvasSize.current.height
      );
      const b = image.data;
      let n;

      const { sin } = Math;
      const { cos } = Math;
      const { atan2 } = Math;

      if (list.current.length === 0) {
        return;
      }

      for (let i = 0; i < list.current.length; i += 1) {
        const p = list.current[i];

        if (p.inRoom) {
          p.ax = 0;
          p.ay = 0;
          if (
            loadingState.current &&
            loadingState.current.state === 'LOADING'
          ) {
            p.ax +=
              ((p.ox - p.x) * loadingState.current.percent +
                (p.sx - p.x) * (1 - loadingState.current.percent) +
                (Math.random() - 0.5) *
                  ((1 - loadingState.current.percent) * 100 + 10)) *
              forceToOrigin;
            p.ay +=
              ((p.oy - p.y) * loadingState.current.percent +
                (p.sy - p.y) * (1 - loadingState.current.percent) +
                (Math.random() - 0.5) *
                  ((1 - loadingState.current.percent) * 100 + 10)) *
              forceToOrigin;
          } else {
            for (let j = 0; j < globalSourcePositions.current.length; j += 1) {
              const pos = globalSourcePositions.current[j];
              if (isSourceActive(j)) {
                // console.log(analyserDataSets[0]);
                // return;
                const color = getColorForSource(j);
                const dx = pos.x - p.x;
                const dy = pos.y - p.y;
                if (dx < 300 && dy < 300) {
                  const d = dx * dx + dy * dy; // abstand mouse dot ohne wurzel
                  const t = atan2(dy, dx);
                  const sint = sin(t);
                  const radius = analyserDataSets[j].data.length
                    ? thicknessPow.current +
                      analyserDataSets[j].sum *
                        (analyserDataSets[j].data[
                          ~~((sint + 1) * 0.5 * analyserDataSets[j].data.length)
                        ] *
                          0.01)
                    : thicknessPow.current;

                  // var radius = THICKNESS + THICKNESS * (analyserDataSets[j][0] / 255 * 10)
                  if (d < radius * 1.5) {
                    p.color[0] = color[0];
                    p.color[1] = color[1];
                    p.color[2] = color[2];
                    if (d < radius) {
                      const f = -radius / d;
                      // set dot on circle
                      p.ax += f * cos(t) * soundSourceForce;
                      p.ay += f * sint * soundSourceForce;
                    }
                  }
                }
              }
            }
            p.ax += (p.ox - p.x) * forceToOrigin;
            p.ay += (p.oy - p.y) * forceToOrigin;
          }
        } else {
          const dx = p.x - roomCenter.current.x;
          const dy = p.y - roomCenter.current.y;
          const d = Math.sqrt(dx ** 2 + dy ** 2);
          if (d < roomRadius.current) {
            p.ax = (dx / d) * 0.2;
            p.ay = (dy / d) * 0.2;
          }
          if (p.x < 0) {
            p.ax = 0.5;
          }
          if (p.x > canvasSize.current.width) {
            p.ax = -0.5;
          }
          if (p.y < 0) {
            p.ay = 0.5;
          }
          if (p.y > canvasSize.current.height) {
            p.ay = -0.5;
          }
          if (Math.random() > 0.98) {
            p.ax = (Math.random() - 0.5) * 0.5;
            p.ay = (Math.random() - 0.5) * 0.5;
          }
        }

        p.vx += p.ax * deltaTime;
        p.vy += p.ay * deltaTime;

        p.vx *= friction ** deltaTime;
        p.vy *= friction ** deltaTime;

        p.x += p.vx * deltaTime;
        p.y += p.vy * deltaTime;

        if (Math.abs(p.x - p.ox) < 0.5) {
          p.x = p.ox;
        }
        if (Math.abs(p.y - p.oy) < 0.5) {
          p.y = p.oy;
        }

        p.color[0] += (gridColor.r - p.color[0]) * 0.1;
        p.color[1] += (gridColor.g - p.color[1]) * 0.1;
        p.color[2] += (gridColor.b - p.color[2]) * 0.1;

        n = (~~p.x + ~~p.y * ~~canvasSize.current.width) * 4;
        // console.log(n);
        b[n] = p.color[0]; // R
        b[n + 1] = p.color[1]; // G
        b[n + 2] = p.color[2]; // B
        b[n + 3] = 255; // alpha
      }

      ctx.putImageData(image, 0, 0);
      const now = performance.now();
      fps.current = 1000 / (now - lastDraw.current);
      lastDraw.current = now;
    },
    [
      thicknessPow,
      forceToOrigin,
      friction,
      getColorForSource,
      gridColor.b,
      gridColor.g,
      gridColor.r,
      isSourceActive,
      soundSourceForce,
    ]
  );

  const drawCenter = useCallback((ctx) => {
    const grd = ctx.createRadialGradient(
      roomCenter.current.x,
      roomCenter.current.y,
      0,
      roomCenter.current.x,
      roomCenter.current.y,
      roomRadius.current * 0.7
    );
    grd.addColorStop(0, '#E9E9E944');
    grd.addColorStop(1, '#32323200');

    // Fill with gradient
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, canvasSize.current.width, canvasSize.current.height);
  }, []);

  const calcGlobalSourcePositions = useCallback(() => {
    const capToRoom = (p) => {
      const newPos = { x: p.x, y: p.y };
      const d = Math.sqrt(p.x ** 2 + p.y ** 2);
      if (d > 1) {
        newPos.x = p.x / d;
        newPos.y = p.y / d;
      }
      return newPos;
    };
    for (let j = 0; j < sourcePositions.current.length; j++) {
      if (useRoom) {
        sourcePositions.current[j] = capToRoom(sourcePositions.current[j]);
      }
      const p = sourcePositions.current[j];

      globalSourcePositions.current[j] = {
        x: roomCenter.current.x + p.x * roomRadius.current,
        y: roomCenter.current.y + p.y * roomRadius.current,
      };
    }
  }, [useRoom]);

  const resizeCanvasToDisplaySize = useCallback(() => {
    const { width, height } = canvasRef.current.getBoundingClientRect();
    imageData = canvasRef.current
      .getContext('2d')
      .createImageData(width, height);
    if (
      canvasRef.current.width !== width ||
      canvasRef.current.height !== height
    ) {
      setUseRoom(width > USE_FULL_SCREEN_BREAKPOINT);
      canvasRef.current.width = width;
      canvasRef.current.height = height;
      canvasSize.current = { width, height };
      calcGlobalSourcePositions();
      return true;
    }
    return false;
  }, [calcGlobalSourcePositions]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    let animationFrameId;

    let time = performance.now();

    const uA = navigator.userAgent;
    const { vendor } = navigator;
    const isSafariDesktop =
      /Safari/i.test(uA) &&
      /Apple Computer/.test(vendor) &&
      !/Mobi|Android/i.test(uA);
    function render() {
      animationFrameId = window.requestAnimationFrame(render);
      currentFrame += 1;
      if (currentFrame % (isSafariDesktop ? 2 : 1) === 0) {
        const analyserDataSets = sourcePositions.current.map((pos, i) => {
          const withoutZeros = getAnalyserData(i).filter((d) => d !== 0);
          return {
            data: withoutZeros,
            sum: withoutZeros.reduce((cur, acc) => acc + cur, 0),
          };
        });

        const currentTime = performance.now();
        const deltaTime = (currentTime - time) / FRAME_DURATION;
        time = currentTime;
        drawParticles(context, analyserDataSets, deltaTime, currentTime);
        drawAudioSources(context, deltaTime);
        // context.globalCompositeOperation = 'lighten';
        drawCenter(context);
        // context.globalCompositeOperation = 'source-over';
      }
    }
    render();
    resizeCanvasToDisplaySize(canvas);

    return () => {
      console.log('cancel animation');
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [
    drawAudioSources,
    drawParticles,
    getAnalyserData,
    drawCenter,
    resizeCanvasToDisplaySize,
  ]);

  useEffect(() => {
    loadingState.current = { ...props.loadingState };
  }, [props]);

  const initPixels = useCallback(() => {
    const canvas = canvasRef.current;
    resizeCanvasToDisplaySize(canvas);
    const { width, height } = canvas.getBoundingClientRect();
    console.log(width, height);
    list.current = [];

    roomRadius.current = Math.min(
      height < width
        ? height * 0.5 - ROOM_OFFSET_Y
        : width * 0.5 - ROOM_OFFSET_Y,
      maxRadius
    );
    roomRadius.current -= ROOM_PADDING;

    sourceSize.current = Math.max(
      Math.min(thickness * (roomRadius.current / MAX_ROOM_RADIUS), thickness),
      20
    );

    thicknessPow.current = sourceSize.current ** 2;

    const outerRoomArea = width * height - Math.PI * roomRadius.current;
    const responsiveSpacing = Math.max(
      (spacing * roomRadius.current) / MAX_ROOM_RADIUS,
      6
    );
    const cols = Math.floor(width / responsiveSpacing);
    const rows = Math.floor(height / responsiveSpacing);
    const restX = width - (cols - 1) * responsiveSpacing;
    const restY = height - (rows - 1) * responsiveSpacing;

    roomCenter.current = {
      x: Math.floor(width * 0.5),
      y: Math.floor(height * 0.5) - ROOM_OFFSET_Y,
    };

    for (let row = 0; row < rows; row += 1) {
      for (let col = 0; col < cols; col += 1) {
        const p = Object.create(particle);
        p.ox = responsiveSpacing * col + restX * 0.5;
        p.oy = responsiveSpacing * row + restY * 0.5;
        p.x = Math.random() * width;
        p.sx = p.x;
        p.y = Math.random() * height;
        p.sy = p.y;
        p.ax = 0;
        p.ay = 0;
        p.color = [...GRID_COLOR];

        const d = Math.sqrt(
          (p.ox - roomCenter.current.x) ** 2 +
            (p.oy - roomCenter.current.y) ** 2
        );
        if (!useRoom) {
          p.inRoom = true;
          list.current.push(p);
        } else if (useRoom && d <= roomRadius.current) {
          p.inRoom = true;
          list.current.push(p);
        }
      }
    }
    if (useRoom) {
      for (
        let i = 0;
        i < outerRoomArea * 0.001 * numberOfParticlesOutsideRoomMultiplier;
        i++
      ) {
        const p = Object.create(particle);
        p.x = Math.random() * width;
        p.sx = p.x;
        p.y = Math.random() * height;
        p.sy = p.y;
        p.ax = 0;
        p.ay = 0;
        p.color = [...GRID_COLOR];
        list.current.push(p);
      }
    }
    console.log(list.current);
  }, [
    maxRadius,
    numberOfParticlesOutsideRoomMultiplier,
    spacing,
    useRoom,
    resizeCanvasToDisplaySize,
    thickness,
  ]);

  useEffect(() => {
    initPixels();
  }, [initPixels]);

  useEffect(() => {
    sourcePositions.current = [...props.sourcePositions];
    calcGlobalSourcePositions();
  }, [props, calcGlobalSourcePositions]);

  useEffect(() => {
    sourcePositionChangeHandler.current = props.onSourcePositionChange;
  }, [props]);

  useEffect(() => {
    sourceMouseOverHandler.current = onSourceMouseOver;
    sourceMouseOutHandler.current = onSourceMouseOut;
  }, [onSourceMouseOver, onSourceMouseOut]);

  useEffect(() => {
    console.log('add eventlisteners');
    const canvas = canvasRef.current;
    // sourcePositions.current = [...props.sourcePositions]

    const getNormalizedPositionInRoon = (p) => {
      const { x } = p;
      const { y } = p;

      const center = {
        x: Math.floor(canvasSize.current.width * 0.5),
        y: Math.floor(canvasSize.current.height * 0.5),
      };
      const distVec = { x: x - center.x, y: y - center.y };
      // const d = Math.sqrt(Math.pow(distVec.x, 2) + Math.pow(distVec.y, 2))

      return {
        x: distVec.x / roomRadius.current,
        y: distVec.y / roomRadius.current,
      };
    };

    const move = (x, y) => {
      const source = getSourceForCursorPos(x, y);

      audioSourceCircleTargetAlpha.current = source >= 0 ? 1 : 0;

      if (source !== -1 || draggingSourceId.current !== -1) {
        canvasRef.current.style.cursor = 'grab';
        if (
          hoveringSourceId.current !== source &&
          draggingSourceId.current === -1
        ) {
          sourceMouseOverHandler.current(source);
        }
        hoveringSourceId.current = source;
      } else {
        canvasRef.current.style.cursor = 'default';
        if (
          hoveringSourceId.current !== -1 &&
          draggingSourceId.current === -1
        ) {
          sourceMouseOutHandler.current();
          hoveringSourceId.current = -1;
        }
      }

      if (draggingSourceId.current > -1) {
        sourcePositionChangeHandler.current(
          draggingSourceId.current,
          getNormalizedPositionInRoon({
            x,
            y: y + ROOM_OFFSET_Y,
          })
        );
      }
    };
    const handleOnMoveEvent = (e) => {
      move(e.clientX, e.clientY);
    };
    canvas.addEventListener('mousemove', handleOnMoveEvent);

    const handleTouchMoveEvent = (e) => {
      e.preventDefault();
      const touches = e.changedTouches;
      move(touches[0].clientX, touches[0].clientY);
    };
    canvas.addEventListener('touchmove', handleTouchMoveEvent, false);

    const getSourceForCursorPos = (x, y) =>
      globalSourcePositions.current.findIndex((pos, i) => {
        const v = Math.sqrt((pos.x - x) ** 2 + (pos.y - y) ** 2);
        return v < sourceSize.current && isSourceActive(i);
      });

    const handleDown = (x, y) => {
      const source = getSourceForCursorPos(x, y);
      draggingSourceId.current = source;
      if (source !== -1) {
        sourceMouseOverHandler.current(source);
      }
    };
    const onDown = (e) => {
      const mx = e.clientX;
      const my = e.clientY;
      handleDown(mx, my);
    };
    canvas.addEventListener('mousedown', onDown);

    const onTouchStart = (e) => {
      e.preventDefault();
      const touches = e.changedTouches;
      const mx = touches[0].clientX;
      const my = touches[0].clientY;
      handleDown(mx, my);
    };
    canvas.addEventListener('touchstart', onTouchStart, false);

    const onUp = (e) => {
      draggingSourceId.current = -1;
      audioSourceCircleTargetAlpha.current = 0;
    };
    const onTouchUp = (e) => {
      hoveringSourceId.current = -1;
      onUp();
    };
    canvas.addEventListener('mouseup', onUp);
    canvas.addEventListener('touchend', onTouchUp, false);
    window.addEventListener('resize', initPixels);

    const onKeyPress = (e) => {
      if (e.key === 'i' && e.ctrlKey) {
        setShowTweakPane(!showTweakPane);
      }
    };
    window.addEventListener('keypress', onKeyPress);

    return () => {
      console.log('remove moise listener');
      canvas.removeEventListener('mousemove', handleOnMoveEvent);
      canvas.removeEventListener('touchmove', handleTouchMoveEvent, false);
      canvas.removeEventListener('mousedown', onDown);
      canvas.removeEventListener('touchstart', onTouchStart, false);
      canvas.removeEventListener('mouseup', onUp);
      canvas.removeEventListener('touchend', onTouchUp, false);
      window.removeEventListener('resize', initPixels);
      window.removeEventListener('keypress', onKeyPress);
    };
  }, [initPixels, setShowTweakPane, showTweakPane]);

  return (
    <>
      <canvas ref={canvasRef} className={className} />
      <div
        className="tp-dfwv"
        ref={tweakRef}
        style={{ display: showTweakPane ? 'block' : 'none' }}
      />
    </>
  );
};

export default Room;
