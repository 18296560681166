import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import styled, { css } from 'styled-components';
import { useTweaks, makeMonitor } from 'use-tweaks';
import axios from 'axios';
import hexRgb from 'hex-rgb';
import './App.css';
import Room from './components/room';
import AudioSourceControls from './components/audioSourceControls';
import PlayerContext from './utils/player';
import { AUDIO_STATES, PLAYER_STATES } from './utils/constants';
import Popup from './components/popup';
import About from './components/about';
import Nav from './components/nav';
import { Button } from './components/styles';
import LoadingScreen from './components/loadingScreen';
import CommentLine from './components/commentLine';

const ROOM_MATERIALS = [
  'transparent',
  'acoustic-ceiling-tiles',
  'brick-bare',
  'brick-painted',
  'concrete-block-coarse',
  'concrete-block-painted',
  'curtain-heavy',
  'fiber-glass-insulation',
  'glass-thin',
  'glass-thick',
  'grass',
  'linoleum-on-concrete',
  'marble',
  'metal',
  'parquet-on-concrete',
  'plaster-smooth',
  'plywood-panel',
  'polished-concrete-or-tile',
  'sheetrock',
  'water-or-ice-surface',
  'wood-ceiling',
  'wood-panel',
  'uniform',
];

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

const StyledRoom = styled(Room)`
  width: 100%;
  height: 100%;
  background: #323232;
  position: absolute;
`;

const StyledNav = styled(Nav)`
  position: absolute;
  left: 10px;
  top: 10px;
  transition: 0.7s transform;
  transform: translate(
    ${(props) => (props.visible ? '0,0' : '-200px, -200px')}
  );
`;

const StyledAudioSourceControls = styled(AudioSourceControls)`
  position: absolute;
  bottom: 10px;
  width: 100%;
  transition: 0.7s transform;
  transform: translateY(${(props) => (props.visible ? '0' : '200px')});
`;

const StyledAboutButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 10px;
  transition: 0.7s transform;
  transform: translate(${(props) => (props.visible ? '0,0' : '200px, -200px')});
`;

const fullscreen = css`
  position: absolute;
  top: 0;
  bottom: 60px;
  width: 100%;
`;

const TutorialContainer = styled.div`
  ${fullscreen};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  text-align: center;
`;

const StyledLoadingScreen = styled(LoadingScreen)`
  ${fullscreen}
`;

const getPreset = (states) =>
  states.map((s) => ({
    x: Math.random() * 2 - 1,
    y: Math.random() * 2 - 1,
  }));

const TUTORIAL_STEPS = [
  'Start by switching on one of the soloists below.',
  'There you go. Now add some other soloists and arrange them the way you like by clicking and dragging them.',
];

// { width: 8, height: 3.2, depth: 8 },
//       {
//         left: 'brick-bare',
//         right: 'brick-bare',
//         up: 'brick-bare',
//         down: 'wood-panel',
//         front: 'brick-bare',
//         back: 'brick-bare',
//       }

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function App() {
  const {
    player,
    audioElementsState,
    downloadProgress,
    audioElements,
    state,
    setRoomProperties,
  } = useContext(PlayerContext);
  const [sourcePositions, setSourcePositions] = useState(
    getPreset(audioElementsState)
  );

  const prevPlayerState = usePrevious(state);

  useEffect(() => {
    audioElements.forEach((a, index) => {
      a.setPosition(sourcePositions[index].x, sourcePositions[index].y);
    });
  }, [audioElements]);

  const getColor = useCallback((i) => player.audioElements[i].color, [
    player.audioElements,
  ]);

  const [showAbout, setShowAbout] = useState(false);
  const [showIosWarning, setShowIosWarning] = useState(false);
  const [globalSettings, setGlobalSettings] = useState(null);
  const [presets, setPresets] = useState([]);
  const [currentPreset, setCurrentPreset] = useState(null);
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const [tutorialEnded, setTutorialEnded] = useState(false);
  const [currentTutorialStep, setCurrentTutorialStep] = useState(0);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [commentLine, setCommentLine] = useState(
    TUTORIAL_STEPS[currentTutorialStep]
  );
  const timeOutRef = useRef(null);

  const {
    radius,
    height,
    distance,
    material,
    // right,
    // up,
    // down,
    // front,
    // back,
  } = useTweaks('ROOM', {
    radius: { value: 6, min: 2, max: 100, step: 1 },
    height: { value: 4, min: 2, max: 50, step: 1 },
    distance: { value: 1, min: 1, max: 50, step: 0.5 },
    material: {
      value: ROOM_MATERIALS[0],
      options: ROOM_MATERIALS,
    },
    // right: {
    //   value: ROOM_MATERIALS[0],
    //   options: ROOM_MATERIALS,
    // },
    // up: {
    //   value: ROOM_MATERIALS[0],
    //   options: ROOM_MATERIALS,
    // },
    // down: {
    //   value: ROOM_MATERIALS[0],
    //   options: ROOM_MATERIALS,
    // },
    // front: {
    //   value: ROOM_MATERIALS[0],
    //   options: ROOM_MATERIALS,
    // },
    // back: {
    //   value: ROOM_MATERIALS[0],
    //   options: ROOM_MATERIALS,
    // },
  });

  useEffect(() => {
    setRoomProperties({
      width: radius,
      height,
      depth: radius,
      left: material,
      right: material,
      up: material,
      down: material,
      front: material,
      back: material,
    });
  }, [radius, height, material, setRoomProperties]);

  useEffect(() => {
    const { userAgent } = window.navigator;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
      setShowIosWarning(true);
    }
  }, []);
  const isSourceActive = useCallback(
    (index) => audioElements[index].state === AUDIO_STATES.UNMUTED,
    [audioElements]
  );

  const nextTutorialStep = useCallback(() => {
    if (currentTutorialStep < TUTORIAL_STEPS.length - 1) {
      setCommentLine(TUTORIAL_STEPS[currentTutorialStep + 1]);
      setCurrentTutorialStep(currentTutorialStep + 1);
    } else {
      // setShowTutorial(false);
      setCommentLine([]);
    }
  }, [currentTutorialStep]);

  const onSourcePositionChange = useCallback(
    (index, pos) => {
      const newPos = [...sourcePositions];
      newPos[index] = pos;
      setSourcePositions(newPos);
      audioElements[index].setPosition(pos.x * distance, pos.y * distance);
      if (currentPreset) {
        setCurrentPreset(null);
      }
      nextTutorialStep();
    },
    [sourcePositions, currentPreset, audioElements]
  );

  const setCommentLineWithTimeout = useCallback(
    (line, timeout) => {
      setCommentLine([line]);
      clearTimeout(timeOutRef.current);
      timeOutRef.current = setTimeout(() => {
        setCommentLine([]);
      }, timeout);
    },
    [timeOutRef]
  );

  useEffect(() => {
    console.log('prev', prevPlayerState);
    if (prevPlayerState === state) {
      return;
    }
    if (!tutorialEnded) {
      return;
    }
    let line = '';
    switch (state) {
      case PLAYER_STATES.PLAYING:
        line = 'Play';
        break;

      case PLAYER_STATES.PAUSED:
        line = 'Pause';
        break;

      case PLAYER_STATES.ENDED:
        line = 'End';
        break;

      default:
        break;
    }
    setCommentLineWithTimeout(line, 3000);
  }, [state, tutorialEnded]);

  const onSourceMouseOver = useCallback(
    (id) => {
      const line = `${audioElements[id].info.artistName} - ${audioElements[id].info.name}`;
      setTutorialEnded(true);
      setCommentLineWithTimeout(line, 3000);
    },
    [audioElements, setCommentLineWithTimeout]
  );

  const onSourceMouseOut = useCallback(() => {
    clearTimeout(timeOutRef.current);
    setCommentLine([]);
  }, []);

  const getAnalyserData = useCallback((i) => player.getAnalyserData(i), [
    player,
  ]);

  useEffect(() => {
    // download
    async function fetchData(url) {
      try {
        const response = await axios.post(url, {
          query: `query Posts {
            posts (first: 500) {
              edges {
                node {
                  id
                  title
                  content
                  audioSource {
                    audioFile
                    color
                    ensembleName
                    city
                    artistName
                  }
                }
              }
            }
            globalSettings {
              globalSettings {
                projectName
                aboutText1
                presetsText
                aboutText2
                introText
                imprintUrl
              }
            }
            presets (first: 500) {
              edges{
                node {
                  title
                  id
                  content
                  preset {
                    audioSources {
                      audioSource {
                        ... on Post {
                          id
                        }
                      }
                      xPosition
                      yPosition
                    }
                  }
                }
              }
            }
          }
        `,
        });

        return response.data.data;
      } catch (e) {
        console.error(e);
        return false;
      }
    }

    fetchData('https://www.einklangfreierwesen-admin.de/wp/graphql').then(
      (data) => {
        const audioSources = data.posts.edges.map((e) => ({
          id: e.node.id,
          url: e.node.audioSource.audioFile,
          color: hexRgb(e.node.audioSource.color, { format: 'array' }),
          colorHex: e.node.audioSource.color,
          artistName: e.node.audioSource.artistName,
          ensembleName: e.node.audioSource.ensembleName,
          city: e.node.audioSource.city,
          name: e.node.title,
          content: e.node.content,
        }));
        setGlobalSettings(data.globalSettings.globalSettings);
        setPresets(data.presets.edges.map((e) => e.node));
        player.loadSources(audioSources).then(() => {
          // SET SOURCE POSITIONS TO PRESETS
          setSourcePositions(getPreset(player.audioElements));
        });
      }
    );
  }, [player]);

  useEffect(() => {
    window.addEventListener('touchstart', () => {
      if (screenHeight !== window.innerHeight) {
        setScreenHeight(window.innerHeight);
      }
    });
    window.addEventListener('resize', () => {
      if (screenHeight !== window.innerHeight) {
        setScreenHeight(window.innerHeight);
      }
    });
    return () => {
      window.removeEventListener('touchstart', () => {
        setScreenHeight(window.innerHeight);
      });
      window.removeEventListener('resize', () => {
        setScreenHeight(window.innerHeight);
      });
    };
  }, [screenHeight]);

  const handlePresetChange = (p) => {
    setCurrentPreset(p);
    const newPositions = [...sourcePositions];
    audioElements.forEach((audioElement, i) => {
      const matchedPresetSource = p.preset.audioSources.find(
        (a) => a.audioSource.id === audioElement.info.id
      );

      if (matchedPresetSource) {
        audioElement.unMute();
        // wp-graphql returns "null" instead of 0 see: https://github.com/wp-graphql/wp-graphql-acf/issues/164
        const x =
          matchedPresetSource.xPosition === null
            ? 0
            : matchedPresetSource.xPosition;
        const y =
          matchedPresetSource.yPosition === null
            ? 0
            : matchedPresetSource.yPosition;

        newPositions[i].x = x;
        newPositions[i].y = y;
        audioElement.setPosition(x, y);
      } else {
        audioElement.mute();
      }
    });
    setSourcePositions(newPositions);
    setShowAbout(false);
    const content = (
      <div>
        <div>{p.title}</div>
        <div dangerouslySetInnerHTML={{ __html: p.content }} />
      </div>
    );
    setCommentLineWithTimeout(content, 5000);
  };

  const randmomizePositions = useCallback(() => {
    const newSourcePositions = audioElements.map((a) => {
      const newX = Math.random() * 2 - 1;
      const newY = Math.random() * 2 - 1;
      a.setPosition(newX, newY);
      return { x: newX, y: newY };
    });
    setSourcePositions(newSourcePositions);
  }, [audioElements]);

  return (
    <Container style={{ height: `${screenHeight}px` }}>
      <>
        <StyledRoom
          getAnalyserData={getAnalyserData}
          sourcePositions={sourcePositions}
          onSourcePositionChange={onSourcePositionChange}
          getColorForSource={getColor}
          isSourceActive={isSourceActive}
          onSourceMouseOver={onSourceMouseOver}
          onSourceMouseOut={onSourceMouseOut}
          loadingState={{
            state: !downloadProgress.complete ? 'LOADING' : 'COMPLETED',
            percent:
              downloadProgress.total > 0
                ? downloadProgress.loaded / downloadProgress.total
                : 0,
          }}
        />

        <StyledAboutButton
          visible={!showLoadingScreen}
          onClick={() => setShowAbout(true)}
        >
          Info
        </StyledAboutButton>

        <StyledNav visible={!showLoadingScreen}>
          <Button
            onClick={() =>
              player.state !== PLAYER_STATES.PLAYING
                ? player.play()
                : player.pause()
            }
          >
            {player.state !== PLAYER_STATES.PLAYING ? 'Play' : 'Pause'}
          </Button>
          <Button onClick={() => player.rewind()}>Rewind</Button>
          <Button onClick={randmomizePositions}>Relocate</Button>
        </StyledNav>
        <StyledAudioSourceControls
          visible={!showLoadingScreen}
          audioElements={audioElements}
          onActivate={() => {
            player.play();
            nextTutorialStep();
          }}
        />
        {showLoadingScreen && globalSettings && (
          <StyledLoadingScreen
            projectName={globalSettings.projectName}
            introText={globalSettings.introText}
            loading={!downloadProgress.complete}
            percent={(downloadProgress.loaded / downloadProgress.total) * 100}
            onClose={() => setShowLoadingScreen(false)}
          />
        )}
        {!showLoadingScreen && (
          <TutorialContainer>
            <CommentLine texts={commentLine} />
          </TutorialContainer>
        )}
        {/* <TutorialContainer>
          <div>{TUTORIAL_STEPS[currentTutorialStep]}</div>
        </TutorialContainer> */}
        <Popup visible={showAbout} onClose={() => setShowAbout(false)}>
          {globalSettings && audioElements && (
            <About
              audioElements={audioElements}
              projectName={globalSettings.projectName}
              text1={globalSettings.aboutText1}
              presetsText={globalSettings.presetsText}
              text2={globalSettings.aboutText2}
              imprintURL={globalSettings.imprintUrl}
              presets={presets}
              currentPreset={currentPreset && currentPreset.id}
              onSelectPreset={(i) => handlePresetChange(presets[i])}
            />
          )}
        </Popup>
        <Popup
          visible={showIosWarning}
          onClose={() => setShowIosWarning(false)}
        >
          <div>
            <p style={{ marginTop: 0 }}>Welcome to Einklang Freier Wesen</p>
            <p>
              If you’re using an IOS device, you’ll may experience some
              performance issues concerning the sound of this application. In
              order to guarantee you a flawless experience, we recommend to use
              a desktop device instead.
            </p>
          </div>
        </Popup>
        {/* <DownloadProgress>{downloadProgress.loaded} - {downloadProgress.total} - {downloadProgress.loaded / downloadProgress.total}</DownloadProgress> */}
      </>
    </Container>
  );
}

export default App;
